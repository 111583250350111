import React, { useContext } from 'react';
import { LoginContext } from '../context/Login';
import { Link } from 'react-router-dom';
import styles from './links.module.scss';

export default function Links() {
    const loginContext = useContext(LoginContext);

    let content = null;

    if (loginContext.loginState.uid == process.env.REACT_APP_ADMINUID) {
        content = (
            <div className={styles.linksComponent}>
                <Link to='/admin'>DASHBOARD</Link>
                <Link to='/admin/createuser'>CREATE USER</Link>
                <Link to='/admin/allusers'>ALL USERS</Link>
            </div>
        )
    }

    return (
        content
    )
}
