import React from 'react';

import { Switch, BrowserRouter, Route, Redirect, useLocation } from 'react-router-dom';

import './App.module.scss';

import Store from './context/Store';

import LandingPage from './pages/landingPage';
import Home from './pages/home';
import Admin from './pages/admin';
import CreateUser from './pages/createUser';
import AllUsers from './pages/allUsers';
import CreatedSuccess from './pages/createdSuccess';
import ContractorView from './pages/contactorView';
import InvoiceList from './pages/invoiceList';
import InvoiceSent from './pages/invoiceSent';
import AdminInvoice from './pages/adminInvoice';
import ChangePassword from './pages/changePassword';

import ContractorForm from './pages/contractor';

import Container from './components/container';

function App() {

  let uid = localStorage.getItem('uid');

  let routes = (
    <>

      <Route path='/' exact>
        <LandingPage/>
      </Route>

      <Route path='/invoiceSuccess' exact>
        <LandingPage success={true}/>
      </Route>

      <Route path='/home' exact>
        <Home />
      </Route>
      <Route path='/admin' exact>
        <Admin />
      </Route>
      <Route path='/admin/createuser' exact>
        <CreateUser />
      </Route>
      <Route path='/admin/allusers' exact>
        <AllUsers />
      </Route>
      <Route path='/admin/usercreatedsuccess' exact>
        <CreatedSuccess />
      </Route>
      <Route path='/admin/contractor/:id' exact>
        <ContractorView />
      </Route>
      <Route path='/admin/contractor/:id/invoices' exact>
        <InvoiceList />
      </Route>
      <Route path='/admin/contractor/:id/invoice/:invoiceid' exact>
        <AdminInvoice />
      </Route>
      <Route path='/admin/changepassword' exact>
        <ChangePassword />
      </Route>

      <Route path='/contractor' exact>
        <ContractorForm />
      </Route>
      <Route path='/contractor/invoicesent' exact>
        <InvoiceSent />
      </Route>
      <Route>
        <Redirect to='/' />
      </Route>



      {/* <Route>
        <Redirect to={localStorage.getItem('uid') == process.env.REACT_APP_ADMINUID ? '/admin' : '/contractor'} />
      </Route> */}
    </>
  )

  return (

    <Store>
      <BrowserRouter>
        <div className="App">
          <Container>
          <Switch>
            {routes}
          </Switch>
          </Container>
        </div>
      </BrowserRouter>
    </Store>

  );
}


export default App;
