import React, { ReactEventHandler, useEffect, useState } from 'react';
import Axios from 'axios';
import { Redirect, useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';

import styles from './contractorView.module.scss';
import Links from '../components/links';



type route = {
    id: string
}

interface details {
    [key: string]: string
}

export default function ContractorView() {

    const { id }: route = useParams();

    const [data, setData] = useState<details>({});
    const [deleteUserModal, setDeleteUserModal] =  useState(false);

    const [newData, setNewData] = useState<details>({});

    const [count, setCount] = useState(0);
    const [err, setErr] = useState<boolean>(false);

    useEffect(() => {
        Axios.get(`https://roseville-database.firebaseio.com/contractors/${id}.json?auth=${localStorage.getItem('token')}`)
            .then(res => {
                setData(res.data.details);
                setNewData(res.data.details);
            })
            .catch(err => {
                setErr(true);
            })
    }, [count]);

    let content = null;

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        let newState = { ...newData };
        newState[key] = e.target.value;
        setNewData(newState);
    }

    const submit = () => {
        Axios.put(`https://roseville-database.firebaseio.com/contractors/${id}/details.json?auth=${localStorage.getItem('token')}`, newData)
            .then(res => {
                setCount(count + 1);
                setErr(false);
            })
            .catch(err => {
                setErr(true);
            })
    }

    if (data) {
        content = (
            // Object.keys(data).map((key: string) => {
            //     return (
            //         <div key={key}>
            //             <label>{key}</label>
            //             <input value={newData[key]} onChange={e => inputChangeHandler(e, key)} />
            //         </div>
            //     )
            // })
            <>
                <div>
                    <label>Email</label>
                    <input value={newData.email} onChange={e => inputChangeHandler(e, 'email')} />
                </div>
                <div>
                    <label>First Name</label>
                    <input value={newData.fname} onChange={e => inputChangeHandler(e, 'fname')} />
                </div >
                <div>
                    <label>Surname</label>
                    <input value={newData.lname} onChange={e => inputChangeHandler(e, 'lname')} />
                </div >
                <div>
                    <label>Ltd. Company Name</label>
                    <input value={newData.companyName} onChange={e => inputChangeHandler(e, 'companyName')} />
                </div >
                <div>
                    <label>Address Line 1</label>
                    <input value={newData.address1} onChange={e => inputChangeHandler(e, 'address1')} />
                </div >
                <div>
                    <label>Address Line 2</label>
                    <input value={newData.address2} onChange={e => inputChangeHandler(e, 'address2')} />
                </div >
                <div>
                    <label>Town</label>
                    <input value={newData.town} onChange={e => inputChangeHandler(e, 'town')} />
                </div >
                <div>
                    <label>County</label>
                    <input value={newData.county} onChange={e => inputChangeHandler(e, 'county')} />
                </div >
                <div>
                    <label>Post Code</label>
                    <input value={newData.postcode} onChange={e => inputChangeHandler(e, 'postcode')} />
                </div >
                <div>
                    <label>Mobile</label>
                    <input value={newData.mobile} onChange={e => inputChangeHandler(e, 'mobile')} />
                </div >
                <div>
                    <label>UTR Number</label>
                    <input value={newData.utr} onChange={e => inputChangeHandler(e, 'utr')} />
                </div >
                <div>
                    <label>National Insurance Number</label>
                    <input value={newData.nationalInsurance} onChange={e => inputChangeHandler(e, 'nationalInsurance')} />
                </div >
                <div>
                    <label>VAT Number</label>
                    <input value={newData.vatNumber} onChange={e => inputChangeHandler(e, 'vatNumber')} />
                </div >
            </>
        )
    }

    let button = null;

    let same = true;

    if (data) {
        Object.keys(data).forEach(key => {
            if (data[key] != newData[key]) {
                same = false;
            }
        })
    }

    if (!same) {
        button = <>
            <button onClick={() => submit()}>SAVE CHANGES</button>
            <button onClick={() => setNewData(data)}>CANCEL</button>
        </>
    }

    return (
        <>
            <Links />
            <div className={styles.contractorView}>
                <h1>CONTRACTOR PROFILE</h1>
                {err ? 'Error updating, please try again' : null}
                <h4>{`${data.fname} ${data.lname}`}</h4>
                <p>*tap any field to edit </p>
                {content}
                {button}
                <Link className={styles.viewInvoices} to={`/admin/contractor/${id}/invoices`}>
                    VIEW INVOICES
                </Link>

                {!deleteUserModal && <button onClick={()=>setDeleteUserModal(true)}>DELETE USER</button>}
                {deleteUserModal && <DeleteUserModal setDeleteUserModal={setDeleteUserModal} id={id}/>}

            </div>
        </>
    )
}

const DeleteUserModal = ({
    id,
    setDeleteUserModal,
}: {
    id: string,
    setDeleteUserModal: any,
}) => {
    const [status, setStatus] = useState<string | null>(null);

    const makeDeleteRequest = () => {
        setStatus('loading');
       
        Axios.delete(`https://roseville-database.firebaseio.com/contractors/${id}.json?auth=${localStorage.getItem('token')}`)
        .then(res=>{
            setStatus('success');
            setTimeout(()=>setStatus('redirect'), 2000);
        })
        .catch(err => {
            console.log(err);
            setStatus('error');
        })

    }

    if(status === 'redirect'){
        return <Redirect to='/admin'/>
    }
    return (
    <div>
        <h2>Are you sure you wish to delete this user?</h2>
        <p>This action cannot be undone.</p>
        {
            status ===  'success' && 
            <>
            <p>Successfully deleted</p>
            <p>
                <Link to='/admin'>
                    Please click here if you are not automatically redirected.
                </Link>
            </p>
            </>
        }
        {
            status === null  && 
            <>
                <button onClick={makeDeleteRequest}>
                    CONFIRM DELETE
                </button>
                <button onClick={()=>setDeleteUserModal(false)}>
                    CANCEL
                </button>
            </>
        }
        {
            status === 'error' &&
            <>
                 <p>Error occured</p>
                <Link to='/admin'>
                    Back to admin page
                </Link>
            </>
        }
    </div>
    )
}
