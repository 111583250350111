import React from 'react';
import LoginProvider from './Login';
import CreateUserProvider from './CreateUser';

const Store = ({ children}: {children: React.ReactNode}) => {
    return (
        <LoginProvider>
            <CreateUserProvider>
            {children}
            </CreateUserProvider>
        </LoginProvider>
    )
}

export default Store;
