import React, { useState } from 'react';
import styles from './landingPage.module.scss';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import emailicon from '../images/emailicon.png';
import link from '../images/link.png';
import checkmark from '../images/checkmark.png';
import invoice from '../images/invoice.png';
import plane from '../images/plane.png';
import supe from '../images/supe.png';


type Props = {
    [key: string]: any
}


export default function LandingPage(props: Props) {

    const [state, setState] = useState<boolean>(props.success);

    const invoiceModal = (
        <div className={styles.invoiceModal}>
            <div>
                Thank you <br/> we have received your Invoice
            <button onClick={() => setState(false)}>CLOSE DIALOGUE</button>
            </div>
        </div>
    )

    return (
        <div className={styles.LandingPage} >

            {state ? invoiceModal : null}
            <div className={styles.hero} >
                <img src={logo} />
                <h2>WELCOME TO ROSEVILLE DECORATING CONTRACTORS</h2>
                <p>A specialist decorating contractor to the new build housing sector</p>
                <p>
                    For our group site, please click <a href='http://www.roseville.co.uk'>here</a>
                </p>
            </div>
            <div className={styles.container}>
                <div className={styles.links} >
                    <img src={link} />
                    <h3>LOOKING TO JOIN US?</h3>
                    <p>
                        We are always looking for experienced site decorators to join our team <br />
                        Please click <a href='https://www.emailmeform.com/builder/form/QRtJ1faAn73afc6b' >here</a> to send us your details, and we will be in contact.
                    </p>
                </div>

                <div className={styles.links} >
                    <img src={checkmark} />
                    <h3>NEWLY APPROVED SUB-CONTRACTORS</h3>
                    <p>
                        Please click <a href='https://www.emailmeform.com/builder/form/01Jxcg7mdRayVlI3bre' >here</a> to get verified.
                    </p>
                </div>

                <div className={styles.links} >
                    <img src={invoice} />
                    <h3>APPROVED <br />SUB-CONTRACTORS</h3>
                    <p>
                        Please click <Link to='/home'>here</Link> to submit your invoice.
                    </p>
                </div>

                <div className={styles.links} >
                    <img src={plane} />
                    <h3>NEED A BREAK?</h3>
                    <p>
                        Please let us know when <br />
                        Please click <a href='https://www.emailmeform.com/builder/form/kdg0j17DWUF16TC7r' >here</a> to send us a holiday notification.
                    </p>
                </div>

                <div className={styles.links} >
                    <img src={supe} />
                    <h3>SUPERVISORS</h3>
                    <ul>
                        <a href='https://www.emailmeform.com/builder/form/cSxlYa22E4Mbw39f5Qd0'> BREWERS ORDER FORM</a>
                        <a href='https://www.emailmeform.com/builder/form/7fWI63vcLRuB7tV7'> DULUX ORDER FORM</a>
                        <a href='https://www.emailmeform.com/builder/form/a4aQq2slf8a49'> VO REQUEST</a>
                        <a href='https://www.emailmeform.com/builder/form/366wbRnQzcd8sS5exOl'> SCP VO</a>
                    </ul>
                </div>

                <div className={styles.links} >
                    <img src={emailicon} />
                    <h3>CONTACT INFORMATION</h3>
                    <p>
                        Roseville Decorating Contractors, <br />
                    Suite 7, <br />
                    340 The Crescent, <br />
                    Colchester, <br />
                    Essex, <br />
                    CO4 9AD
                    <br /><br />
                    Email: <a href="mailto:decorators@roseville.co.uk">decorators@roseville.co.uk</a>
                    </p>
                </div>
            </div>
        </div>

    )
}