import React, { useState, useContext } from 'react';

import { CreateUserContext } from '../context/CreateUser';
import { LoginContext } from '../context/Login';


import styles from './createUser.module.scss';
import { Redirect } from 'react-router-dom';
import Links from '../components/links';


interface myState {
    [key: string]: string
}

const initialProps = {
    email: '',
    password: '',
    fname: '',
    lname: '',
    companyName: '',
    address1: '',
    address2: '',
    town: '',
    county: '',
    postcode: '',
    mobile: '',
    utr: '',
    nationalInsurance: '',
    vatNumber: '', 
    invoiceStartNumber: ''
}

export default function CreateUser() {



    const [state, setState] = useState<myState>(initialProps);

    const createUserContext = useContext(CreateUserContext);
    const loginContext = useContext(LoginContext);

    if (loginContext.loginState.uid != process.env.REACT_APP_ADMINUID) return <Redirect to='/contractor' />


    if (createUserContext.createState.success) {
        return (
            <Redirect to='/admin/usercreatedsuccess' />
        )
    }

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, field: string) => {
        let newState = {
            ...state,
            [field]: e.target.value
        };

        setState(newState);
    }

    const placeholders = [
        'Email',
        'Password (min. 6 char)',
        'First Name',
        'Surname',
        'Ltd. Company Name',
        'Address Line 1',
        'Address Line 2',
        'Town',
        'County',
        'Postcode',
        'Mobile',
        'UTR Number',
        'National Insurance Number',
        'VAT Number', 
        'Invoice Starting Number'
    ];

    const submit = () => {
        createUserContext.submit(state);
    }

    return (
        <>
            <Links />
            <div className={styles.createUser} >
                <h1>CREATE NEW USER</h1>
                {createUserContext.createState.error ? 'Error: try again' : null}
                {
                    Object.keys(state).map((field, index) => {

                        if (field == 'invoiceStartNumber'){
                            return (
                                <input
                                    type="number"
                                    key={field}
                                    placeholder={placeholders[index]}
                                    onChange={e => inputChangeHandler(e, field)}
                                    value={state[field]}
                                />
                            );
                        } else {
                            return (
                                <input
                                    type="text"
                                    key={field}
                                    placeholder={placeholders[index]}
                                    onChange={e => inputChangeHandler(e, field)}
                                    value={state[field]}
                                />
                            );
                        }
                        
                    })
                }
                <button onClick={() => submit()}>CREATE USER</button>
            </div>
        </>
    )
}