import React, { useEffect, useState } from 'react';

import Axios from 'axios';

import { useParams, Link } from 'react-router-dom';

import styles from './invoiceList.module.scss';
import Links from '../components/links';


type route = {
    id: string
}

interface invoices {
    [key: string]: string
}


export default function InvoiceList() {

    const { id }: route = useParams();

    const [invoices, setInvoices] = useState<invoices[]>([]);
    const [nothing, setNothing] = useState<boolean>(false);

    

    useEffect(() => {
        let url = `https://roseville-database.firebaseio.com/contractors/${id}/invoices.json?auth=${localStorage.getItem('token')}`

        Axios.get(url)
            .then(res => {
                if (res.data) {
                    let data = Object.keys(res.data).map(invoice => {
                        return {...res.data[invoice], key: invoice};
                    }); 
                    setNothing(false); 
                    setInvoices(data);
                } else {
                    setNothing(true);
                }
            })

    }, []);


    return (
        <>
        <Links/>
        <div className={styles.invoiceList}>
            <h1>INVOICE LIST</h1>
            <div>
                <span>Invoice number</span>
                <span>Date Submitted yyyy-mm-dd</span>
            </div>

            {
                nothing ? <div>No invoices for this contractor.</div> : null
            }

            {
                invoices ? invoices.map(invoice => {
                    return (
                        <Link className={styles.rowhold} to={`/admin/contractor/${id}/invoice/${invoice.key}`}>
                            <div className={styles.row}>
                                <span>{invoice.invoice_number}</span>
                                <span>{invoice.date_submitted.substring(0, 10)}</span>
                            </div>
                        </Link>
                    )
                }) : null
            }
        </div>
        </>
    )
}