import React from 'react';

import { Link } from 'react-router-dom';

import styles from './admin.module.scss';

import Links from '../components/links';

export default function Admin() {
    return (
        <>
            <Links />
            <div className={styles.admin}>
                <h1>ADMIN</h1>
                <Link className={styles.button} to='/admin/createuser'>
                    CREATE CONTRACTOR
                </Link>
                <Link className={styles.button} to='/admin/allusers'>
                    ALL CONTRACTORS
                </Link>
                <Link className={styles.button} to='/admin/changepassword'>
                    CHANGE PASSWORD
                </Link>
            </div>
        </>
    )
}