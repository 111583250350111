import React, { useState } from 'react';
import Axios from 'axios';

const initCreateUser = {
    loading: false,
    success: false,
    error: false,
}

interface createState {
    loading: boolean,
    success: boolean,
    error: boolean | string
}

interface details {
    [key: string]: string
}

let ls: any = {}

export const CreateUserContext = React.createContext({
    submit: (details: details) => { },
    reset: () => { },
    createState: ls
});

export default (props: any) => {
    const [createState, setCreateState] = useState<createState>(initCreateUser);

    const reset = () => {
        setCreateState(initCreateUser)
    }

    const submit = async (details: details) => {

        setCreateState({
            loading: true,
            success: false,
            error: false
        });

        let payload1 = {
            email: details.email,
            password: details.password
        }

        const res = await Axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.REACT_APP_API_KEY}`, payload1)
            .catch(err => {
                setCreateState({
                    loading: false,
                    success: false,
                    error: true,
                })
                console.log('[ERR]', err);
            })

        if (!res) return;

        let token = localStorage.getItem('token');

        delete details.password;

        Axios.put(`https://roseville-database.firebaseio.com/contractors/${res.data.localId}/details.json?auth=${token}`, details)
            .then(res=>{
                setCreateState({
                    loading: false,
                    success: true,
                    error: false,
                });
            })
            .catch(err=>{
                setCreateState({
                    loading: false,
                    success: false,
                    error: true,
                });
            })

        
    }

    return (
        <CreateUserContext.Provider value={{ submit, createState, reset }}>
            {props.children}
        </CreateUserContext.Provider>
    )
}