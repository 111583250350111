import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useParams, Link, Redirect, useHistory} from 'react-router-dom';
import Links from '../components/links';
import DatePicker from 'react-date-picker';

import styles from './adminInvoice.module.scss';

type route = {
    id: string,
    invoiceid: string
}

interface invoice {
    [key: string]: string | boolean
}

type job_bcd = {
    [key: string]: any
}

let today: Date = new Date();

export default function AdminInvoice() {

    const { id, invoiceid }: route = useParams();

    const [meta, setMeta] = useState<invoice>({});
    const [decoration, setDecoration] = useState<job_bcd[]>([{}]);
    const [mistcoats, setMistcoats] = useState<job_bcd[]>([{}]);
    const [dayworks, setdayworks] = useState<job_bcd[]>([{}]);

    const [newmeta, setNewMeta] = useState<invoice>({});
    const [newdecoration, setNewDecoration] = useState<job_bcd[]>([{}]);
    const [newmistcoats, setNewMistcoats] = useState<job_bcd[]>([{}]);
    const [newdayworks, setNewDayworks] = useState<job_bcd[]>([{}]);

    const [count, setCount] = useState(0);
    const [err, setErr] = useState<boolean>(false);
    const [updated, setUpdated] = useState(false);
    const [emailed, setEmailed] = useState(false);

    const history = useHistory();

    useEffect(() => {
        Axios.get(`https://roseville-database.firebaseio.com/contractors/${id}/invoices/${invoiceid}.json?auth=${localStorage.getItem('token')}`)
            .then(res => {

                if (updated) sendPDF(id, invoiceid);

                setMeta({
                    _CISApplied: res.data._CISApplied,
                    _VATApplied: res.data._VATApplied,
                    finaltotal: res.data.finaltotal,
                    subtotal: res.data.subtotal
                });

                setNewMeta({
                    _CISApplied: res.data._CISApplied,
                    _VATApplied: res.data._VATApplied,
                    finaltotal: res.data.finaltotal,
                    subtotal: res.data.subtotal
                });

                setDecoration(res.data.decoration);
                setNewDecoration(res.data.decoration);

                setdayworks(res.data.dayworks);
                setNewDayworks(res.data.dayworks);

                setMistcoats(res.data.mistcoats);
                setNewMistcoats(res.data.mistcoats);
            })
            .catch(err => {
                setErr(true);
            });

    }, [count]);

    const sendPDF = (id: string, invoice: string) => {
        const url = `https://eager-lewin-26fb51.netlify.app/.netlify/functions/test`;
        // const url = 'http://localhost:9000/test';
        Axios.post(url, JSON.stringify({ id, invoicecode: invoice, token: localStorage.getItem('token') }))
            .catch(err => null)
            .then(res => {
                setEmailed(true);
            })
    }

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>, dataset: job_bcd[], index: number, field: string, stateChanger: any) => {
        let newState = [...dataset];
        newState[index][field] = e.target.value;
        stateChanger(newState);
        setUpdated(false);
        setEmailed(false);
    }

    const dateChangeHandler = (e: any, dataset: job_bcd[], index: number, field: string, stateChanger: any) => {
        let newState = [...dataset];
        newState[index][field] = e;
        e.setHours(12);
        stateChanger(newState);
        setUpdated(false);
        setEmailed(false);
    }

    const removeItem = (arr: job_bcd[], stateChanger: any, pos: number) => {
        let newArr = [...arr];
        newArr.splice(pos, 1);
        stateChanger(newArr);
    }

    const labels: job_bcd = {
        site_no: 'Site Number',
        site_name: 'Site Name',
        plot_number: 'Plot Number',
        date_completed: 'Date Completed',
        price: 'Price'
    };

    const genfields = (dataset: job_bcd[], stateChanger: any, name: string) => {
        return dataset.map((task, index) => {

            let date = task.date_completed;

            if (typeof (task.date_completed) == 'string') {
                let year = parseInt(task.date_completed.substring(0, 4));
                let month = parseInt(task.date_completed.substring(5, 7));
                let day = parseInt(task.date_completed.substring(8, 10));
                date = new Date(year, month - 1, day);
            }

            return (
                <div className={styles.workContainer}>
                    <span className={styles.containerLabel}>{name}</span>
                    <div className={styles.field}>
                        <label>Site Number</label>
                        <input type="text" value={task.site_no} onChange={e => changeHandler(e, dataset, index, 'site_no', stateChanger)} />
                    </div>

                    <div className={styles.field}>
                        <label>Site Name</label>
                        <input type="text" value={task.site_name} onChange={e => changeHandler(e, dataset, index, 'site_name', stateChanger)} />
                    </div>

                    <div className={styles.field}>
                        <label>Plot Number</label>
                        <input type="text" value={task.plot_number} onChange={e => changeHandler(e, dataset, index, 'plot_number', stateChanger)} />
                    </div>

                    <div className={styles.field}>
                        <label>Date Completed</label>
                        <div className={styles.datepicker}>
                            <DatePicker clearIcon={null} value={date} onChange={e => dateChangeHandler(e, dataset, index, 'date_completed', stateChanger)} />
                        </div>
                    </div>

                    <div className={styles.field}>
                        <label>Price (£)</label>
                        <input type="text" value={task.price} onChange={e => changeHandler(e, dataset, index, 'price', stateChanger)} />
                    </div>

                    { dataset.length > 1 ? <button onClick={() => removeItem(dataset, stateChanger, index)}> remove </button> : null}

                </div>
            )
        })
    };

    const misthtml = genfields(newmistcoats, setNewMistcoats, 'MISTCOATS');
    const decohtml = genfields(newdecoration, setNewDecoration, 'DECORATION');
    const dayhtml = genfields(newdayworks, setNewDayworks, 'DAYWORKS');

    const sumPrice = (arr: job_bcd[]) => {
        return arr.reduce((total: number, work: job_bcd) => {
            return (parseFloat(work.price) || 0) + total;
        }, 0);
    }

    const addField = (field: job_bcd[], stateChanger: any) => {
        if (field.length < 20) {
            let newState = [...field];
            newState.push({ date_completed: today, plot_number: '', price: '', site_name: '', site_no: '' });
            stateChanger(newState);
        }
    }

    const submit = () => {

        setUpdated(false);
        setEmailed(false);

        const total = sumPrice(newdecoration) + sumPrice(newmistcoats) + sumPrice(newdayworks);

        let VATTotal = newmeta._VATApplied ? total * 0.2 : 0;
        let CISTotal = newmeta._CISApplied ? total * 0.2 : 0;

        let payload = {
            mistcoats: newmistcoats,
            dayworks: newdayworks,
            decoration: newdecoration,
            subtotal: total,
            finaltotal: total + VATTotal - CISTotal,
            _VATApplied: newmeta._VATApplied, 
            _CISApplied: newmeta._CISApplied
        };


        Axios.patch(`https://roseville-database.firebaseio.com/contractors/${id}/invoices/${invoiceid}.json?auth=${localStorage.getItem('token')}`, payload)
            .then(res => {
                setUpdated(true);
                setCount(count + 1);
            })
            .catch(err => {
                setErr(true);
            })

    }

    const deleteInvoice = () => {
        Axios.delete(`https://roseville-database.firebaseio.com/contractors/${id}/invoices/${invoiceid}.json?auth=${localStorage.getItem('token')}`)
        .then(res =>{
            history.goBack();
        })
    }

    const sTotal = sumPrice(newdecoration) + sumPrice(newmistcoats) + sumPrice(newdayworks);
    let vat = 0;
    let cis = 0;

    if(newmeta._VATApplied){
        vat = 0.2*sTotal;
    }

    if(newmeta._CISApplied){
        cis = 0.2*sTotal;
    }

    let finaltotal = (sTotal - cis + vat).toFixed(2);

    const statesEqual = () => {
        let same = true; 
        Object.keys(newmeta).forEach(key=>{
            if(newmeta[key] != meta[key]) same = false; 
        }); 

        return same;
    }

    const button = newdayworks != dayworks || newdecoration != decoration || newmistcoats != mistcoats || !statesEqual();

    if (err) return <Link to='/' >Error: please click here to log back in </Link>;

    return (
        <>
            <Links />
            <div className={styles.adminInvoice} >
                <h1>EDIT INVOICE</h1>
                {updated ? <span className={styles.updatedbanner}>UPDATED SUCCESSFULLY</span> : null}
                {emailed ? <span className={styles.updatedbanner}>EMAIL INVOICE SENT</span> : null}
                <h3>DECORATION</h3>
                {decohtml}
                <button onClick={() => addField(newdecoration, setNewDecoration)}>add more</button>
                <br />
                <hr />
                <br />

                <h3>MISTCOATS</h3>
                {misthtml}
                <button onClick={() => addField(newmistcoats, setNewMistcoats)}>add more</button>
                <br />
                <hr />
                <br />

                <h3>DAYWORKS</h3>
                {dayhtml}
                <button onClick={() => addField(newdayworks, setNewDayworks)}>add more</button>
                <br />
                <hr />
                <br />

                <h3>SUBTOTAL: £{sTotal.toFixed(2)}</h3>
                <p>{newmeta._VATApplied ? 'VAT 20%: £' + (sTotal * 0.2).toFixed(2) : 'VAT 0%: £0.00'}</p>
                <button className={styles.special} onClick={() => {
                    let newState = {...newmeta};
                    newState._VATApplied = !newState._VATApplied;
                    setNewMeta(newState);
                }}> {newmeta._VATApplied ? 'REMOVE VAT' : 'APPLY VAT'}</button>
                <br />

                <p>{newmeta._CISApplied ? 'CIS Tax | Deduct 20%: -£' + (sTotal * 0.2).toFixed(2) : 'CIS Tax | Deduct 0%'}</p>
                <button className={styles.special} onClick={() => {
                    let newState = {...newmeta};
                    newState._CISApplied = !newState._CISApplied;
                    setNewMeta(newState);
                }}>{newmeta._CISApplied ? 'CHANGE TO GROSS STATUS | DEDUCT 0%' : 'APPLY CIS TAX | DEDUCT 20%'}</button>

                <h3>INVOICE TOTAL: £{finaltotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                {button ?
                    <>
                        <button onClick={() => submit()}>UPDATE INVOICE</button>
                        <button onClick={() => setCount(count + 1)}>CANCEL EDIT</button>
                    </>
                    : null}
                    <button onClick={() => deleteInvoice()}>DELETE THIS INVOICE</button>
            </div>
        </>
    )
}