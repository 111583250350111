import Axios from 'axios';
import React, { ReactHTMLElement, useState } from 'react';

import Links from '../components/links';

import styles from './changePassword.module.scss';

type myState = {
    [key: string]: string
}

export default function ChangePassword() {

    const [worked1, setWorked1] = useState<boolean>(false);
    const [worked2, setWorked2] = useState<boolean>(false);

    const [err, setErr] = useState<boolean>(false);

    const [credentials, setCredentials] = useState<myState>({ email: '', password: '', newPassword: '' });

    const submit = () => {
        setErr(false);
        setWorked1(false);
        setWorked2(false);
        Axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.REACT_APP_API_KEY}`, { email: credentials.email, password: credentials.password, returnSecureToken: true })
            .then(res => {
                setWorked1(true);
                changePassword(res.data.idToken);
            })
            .catch(err => {
                setErr(true);
            })
    }

    const changePassword = (token: string) => {
        Axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:update?key=${process.env.REACT_APP_API_KEY}`, { idToken: token, password: credentials.newPassword, returnSecureToken: true })
            .then(res => {
                setWorked1(false);
                setWorked2(true);
            })
            .catch(err => {
                setErr(true);
            })
    }

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        let newState = { ...credentials };
        newState[field] = e.target.value;
        setCredentials(newState);
    }

    return (
        <>
            <Links />

            <div className={styles.changePassword} >
                <h1>
                    CHANGE USER PASSWORD
            </h1>
                {err ? <span className={styles.notice}>Oops... Something went wrong</span> : null}
                {worked1 ? <span className={styles.notice}>loading...</span> : null}
                {worked2 ? <span className={styles.notice}>Password changed, make a note of this somewhere safe</span> : null}
                <div>
                    <label>Email</label>
                    <input value={credentials.email} onChange={e => changeHandler(e, 'email')} type="text" />

                    <label>Current Password</label>
                    <input value={credentials.password} onChange={e => changeHandler(e, 'password')} type="text" />

                    <label>New Password (min 6 characters)</label>
                    <input value={credentials.newPassword} onChange={e => changeHandler(e, 'newPassword')} type="text" />

                    <button onClick={() => submit()}>CHANGE PASSWORD</button>
                </div>
            </div>
        </>
    )
}