import Axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { defaultMaxListeners } from 'stream';
import Links from '../components/links';

import styles from './allUsers.module.scss';

const users = [
    {
        name: 'John S.',
        lname: 'jackson',
        company: 'Company ltd'
    },
    {
        name: 'Jane D.',
        lname: 'donners',
        company: 'Neutron ltd'
    },
    {
        name: 'James C.',
        lname: 'jackson',
        company: 'n/a'
    },
];

export default function AllUsers() {

    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');

    const filterRes = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value.toUpperCase());
    }

    useEffect(() => {
        Axios.get(`https://roseville-database.firebaseio.com/contractors.json?auth=${localStorage.getItem('token')}`)
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                setError(true);
            })
    }, []);

    let content = null;

    if (data) {
        content = Object.keys(data).map(userstr => {

            let user = data[userstr].details;

            if ((filter && (
                data[userstr].details.fname.toUpperCase().includes(filter)
                ||
                data[userstr].details.lname.toUpperCase().includes(filter)
                ||
                data[userstr].details.companyName.toUpperCase().includes(filter)
            )) || !filter){
                return (
                    <div key={userstr} className={styles.contractor}>
                        <Link key={userstr} to={`/admin/contractor/${userstr}`}>
                            <span>{user.fname.substring(0, 1)}.</span>
                            <span>{user.lname}</span>
                            <span>{user.companyName || 'N/A'}</span>
                        </Link>
                    </div >
                );
            }
        })
    }

    if (error) {
        return (
            <>
                <h3>Error, please log back in</h3>
                <Link to='/home'> Log In </Link>
            </>
        )
    }

    return (
        <>
            <Links />
            <div className={styles.allUsers}>
                <h1>ALL CONTRACTORS</h1>
                <input placeholder='Search by Name/Company' onChange={e => filterRes(e)} />
                <div className={styles.titles}>
                    <span>First Name</span>
                    <span>Last Name</span>
                    <span>Company</span>
                </div>
                <div>
                    {
                        content
                    }
                </div>
            </div>
        </>
    )
}