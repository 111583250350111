import React, { useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { LoginContext } from '../context/Login';

import styles from './container.module.scss';

type myprops = {
    children: any
}

export default function Container(props: myprops) {

    const here = useLocation()
    const loginContext = useContext(LoginContext);

    const [modal, setModal] = useState<string>('');

    useEffect(() => {
        if (loginContext.loginState.exp && new Date().getTime() > loginContext.loginState.exp) {
            setModal('WARNING: Session expired. Please log back in');
        } else if (loginContext.loginState.exp && new Date().getTime() > loginContext.loginState.exp - (1000 * 5 * 60)) {
            setModal('Session expires in 5 minutes');
        } else if (loginContext.loginState.exp && new Date().getTime() > loginContext.loginState.exp - (1000 * 30 * 60)) {
            setModal('Session expires in 30 minutes');
        } else if (loginContext.loginState.exp){
            setModal("You're logged in");
        }

        console.log(loginContext.loginState.exp);
        console.log(modal);

    }, [here.pathname]);


    return (
        <>
            {modal ? <span className={styles.logbar}>{modal}</span> : null}
            {props.children}
        </>
    )
}