import React, { useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { CreateUserContext } from '../context/CreateUser';

export default function CreatedSuccess() {

    const context = useContext(CreateUserContext)

    useEffect(() => {
        context.reset();
    }, [])

    return (
        <>
            <h1>USER CREATED</h1>
            <Link to='/admin/createuser'>back</Link>
        </>
    )
}