import React, { useState } from 'react';
import Axios from 'axios';

interface creds{
    [key: string]: string
}

interface loginState{
    loading: boolean, 
    success: boolean, 
    error: boolean | string, 
    email: string | null, 
    uid: string | null, 
    exp: number | null
}

const initLogin = {
    loading: false,
    success: false,
    error: false, 
    email: localStorage.getItem('email')  || null, 
    uid: localStorage.getItem('uid') || null, 
    exp: null
}

let ls: any = {}

export const LoginContext = React.createContext({
    reset: ()=>{}, 
    logout: ()=>{}, 
    submit: (creds: creds)=>{}, 
    loginState: ls
});

export default (props: any) => {
    const [loginState, setLoginState] = useState<loginState>(initLogin);

    const reset = () => {
        setLoginState(initLogin);
    }

    const logout = () => {
        localStorage.removeItem('email');
        localStorage.removeItem('token');
        localStorage.removeItem('uid');
        setLoginState({
            loading: false,
            success: false,
            error: false, 
            email: null, 
            uid: null, 
            exp: null
        })

    }

    const submit = (creds: creds) => {
        localStorage.removeItem('email');
        localStorage.removeItem('token');
        localStorage.removeItem('uid');

        setLoginState({
            loading: true,
            success: false,
            error: false, 
            email: null, 
            uid: null, 
            exp: null
        })

        let credentials = {...creds, returnSecureToken: true}

        let now = new Date().getTime() + (1000 * 60 * 60);

        Axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.REACT_APP_API_KEY}`, credentials)
            .then(res => {
                setLoginState({
                    loading: false, 
                    success: true, 
                    error: false,
                    email: creds.email, 
                    uid: res.data.localId, 
                    exp: now
                })

                localStorage.setItem('email', creds.email);
                localStorage.setItem('token', res.data.idToken);
                localStorage.setItem('uid', res.data.localId);
            })
            .catch(err => {
                setLoginState({
                    loading: false, 
                    success: false, 
                    error: true, 
                    email: null, 
                    uid: null, 
                    exp: null
                })
            })
    }

    return (
        <LoginContext.Provider value={{ submit, loginState, reset, logout }}>
            {props.children}
        </LoginContext.Provider>
    )
}