import React, { useState } from 'react';

import LoginModal from '../components/loginModal';
import Logo from '../images/logo.png';

import styles from './home.module.scss';


export default function Home() {

    const [state, setState] = useState('');

    let modal = null;

    if (state) {
        modal = <LoginModal loginType={state} />
    }

    const close = () => {
        if(state){
            setState('');
        }
    }

    return (
        <>
        <div className={styles.home} onClick={()=>close()} >
            <header>
                <img src={Logo} />
                <div></div>
            </header>
            <span
                onClick={() => setState('ADMIN')}>
                ROSEVILLE STAFF
            </span>
            <button
                onClick={() => setState('CONTRACTOR')}>
                CONTRACTOR
            </button>
        </div>
        {modal}
        </>
    )
}