import React, { useState, useEffect, useContext } from 'react';

import { LoginContext } from '../context/Login';
import Axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import DatePicker from 'react-date-picker';

import styles from './contractor.module.scss';

interface myState {
    [key: string]: string
}

type job_bcd = {
    [key: string]: any
}

const initialProps = {

}

let today: Date = new Date();

export default function ContractorForm() {

    const loginContext = useContext(LoginContext);
    const id = loginContext.loginState.uid || localStorage.getItem('uid');

    const [fixedData, setFixedData] = useState<myState>();

    const [decoration, setDecoration] = useState<job_bcd[]>([{ site_no: null, site_name: '', plot_number: null, date_completed: today, price: null }]);
    const [mistcoats, setMistcoats] = useState<job_bcd[]>([{ site_no: null, site_name: '', plot_number: null, date_completed: today, price: null }]);
    const [dayworks, setDayworks] = useState<job_bcd[]>([{ site_no: null, site_name: '', plot_number: null, date_completed: today, price: null }]);

    const [count, setCount] = useState<number>(0);

    const [totals, setTotals] = useState<number>(0);

    const [VAT, setVAT] = useState<boolean>(false);
    const [CIS, setCIS] = useState<boolean>(true);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<boolean>(false);

    const [showSubmit, setShowSubmit] = useState<boolean>(true);

    // useEffect(() => {
    //     Axios.get(`https://roseville-database.firebaseio.com/contractors/${id}/details.json?auth=${localStorage.getItem('token')}`)
    //         .then(res => {
    //             setFixedData(res.data);
    //             setCount(res.data.invoiceStartNumber);
    //         })
    //         .catch(err => {
    //             setError(true);
    //         })
    // }, []);

    // useEffect(() => {
    //     Axios.get(`https://roseville-database.firebaseio.com/contractors/${id}/invoices.json?auth=${localStorage.getItem('token')}`)
    //         .then(res => {
    //             if (res.data) {
    //                 let max: number[] = Object.keys(res.data).map(item => parseInt(res.data[item].invoice_number));
    //                 const mx = Math.max(...max);
    //                 setCount(mx + 1);
    //             }
    //         })
    // }, []);

    useEffect(() => {
        Axios.get(`https://roseville-database.firebaseio.com/contractors/${id}/details.json?auth=${localStorage.getItem('token')}`)
            .then(res => {
                setFixedData(res.data);
                setCount(res.data.invoiceStartNumber);
            })
            .catch(err => {
                console.log(err)
                setError(true);
            })
    }, []);

    useEffect(() => {
        Axios.get(`https://roseville-database.firebaseio.com/contractors/${id}/invoices.json?auth=${localStorage.getItem('token')}`)
            .then(res => {
                if (res.data) {
                    let max: number[] = Object.keys(res.data).map(item => parseInt(res.data[item].invoice_number));
                    const mx = Math.max(...max);
                    setCount(mx + 1);
                }
            })
    }, [fixedData]);

    useEffect(() => {
        let x = sumPrice(decoration) + sumPrice(mistcoats) + sumPrice(dayworks);

        setTotals(
            x
        );
    }, [decoration, mistcoats, dayworks]);

    if (success) {
        return <Redirect to='/contractor/invoicesent' />
    }

    let content = null;


    if (fixedData) {
        // content = Object.keys(fixedData).map(field => {
        //     if (field) {
        //         return (
        //             <label className={styles.fixedData}> {field}: {fixedData[field]}</label>
        //         )
        //     }
        content = (
            <div className={styles.fixedContainer}>
                <label>{fixedData.fname} {fixedData.lname}</label>
                <label>{fixedData.companyName ? 'Company Name: ' + fixedData.companyName : null}</label>
                <label>{fixedData.address1}</label>
                <label>{fixedData.address2}</label>
                <label>{fixedData.town}</label>
                <label>{fixedData.county}</label>
                <label>{fixedData.postcode}</label>
                <label>Mobile: {fixedData.mobile}</label>
                <label>Email: {fixedData.email}</label>
                <label>UTR Number: {fixedData.utr}</label>
                <label>National Insurance: {fixedData.nationalInsurance}</label>
                <label>VAT Number: {fixedData.vatNumber}</label>
                <label>{'invoice number: ' + (count)}</label>
            </div>
        )
    }

    const addField = (field: job_bcd[], stateChanger: any) => {
        if (field.length < 20) {
            let newState = [...field];
            newState.push({ site_no: '', site_name: '', plot_number: '', date_completed: today, price: '' });
            stateChanger(newState);
        }
    }

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>, dataset: job_bcd[], index: number, field: string, stateChanger: any) => {
        let newState = [...dataset];
        newState[index][field] = e.target.value;
        stateChanger(newState);
    }

    const dateChangeHandler = (e: any, dataset: job_bcd[], index: number, field: string, stateChanger: any) => {
        if(!e){
            return null;
        }
        let newState = [...dataset];
        e.setHours(12);
        newState[index][field] = e;
        stateChanger(newState);
    }

    const deleteEntry = (dataSet: job_bcd[], stateChanger: any, index: number) => {
        let newArr = [...dataSet];
        newArr.splice(index, 1);
        stateChanger(newArr);
    }

    const labels: job_bcd = {
        site_no: 'Site Number',
        site_name: 'Site Name',
        plot_number: 'Plot Number',
        date_completed: 'Date Completed',
        price: 'Price'
    };

    const genfields = (dataset: job_bcd[], stateChanger: any, name: string) => {
        return dataset.map((task, index) => {
            let fields = Object.keys(task).map(field => {
                let input = <input type="text" value={task[field]} onChange={e => changeHandler(e, dataset, index, field, stateChanger)} />

                if (field == 'date_completed') {
                    input =
                        <div className={styles.datepicker}>
                            <DatePicker value={task[field]} onChange={e => dateChangeHandler(e, dataset, index, field, stateChanger)} />
                        </div>
                }

                return (
                    <div>
                        <label>{labels[field]}{field == 'price' ? ' (£)' : ''}</label>
                        {input}
                    </div>
                );
            });

            return (
                <div className={styles.workContainer}>
                    <span>{name}</span>
                    {fields}
                    {dataset.length > 1 ? <button onClick={() => deleteEntry(dataset, stateChanger, index)}>REMOVE ENTRY</button> : null}
                </div>
            )
        })
    };

    const sumPrice = (arr: job_bcd[]) => {
        return arr.reduce((total: number, work: job_bcd) => {
            return (parseFloat(work.price) || 0) + total;
        }, 0);
    }

    let decorationhtml = genfields(decoration, setDecoration, 'DECORATION');
    let mistcoatshtml = genfields(mistcoats, setMistcoats, 'MISTCOATS');
    let dayworkshtml = genfields(dayworks, setDayworks, 'DAYWORKS');

    const VATTotal = VAT ? (0.2 * totals) : 0;
    const CISTotal = CIS ? (0.2 * totals) : 0;
    let finaltotal = totals + VATTotal - CISTotal;

    //we need to get the user info, then when it's ready, propagate the form

    const submit = () => {

        setShowSubmit(false);

        if (fixedData) {
            Axios.post(`https://roseville-database.firebaseio.com/contractors/${id}/invoices.json?auth=${localStorage.getItem('token')}`, {
                decoration,
                mistcoats,
                dayworks,
                subtotal: totals,
                _VATApplied: VAT,
                _CISApplied: CIS,
                finaltotal,
                date_submitted: new Date(),
                invoice_number: count
            })
                .then(res => {
                    sendPDF(id, res.data.name);
                })
                .catch(err => {
                    setError(true);
                });
        }
    }

    const sendPDF = (id: string, invoice: string) => {
        Axios.post('https://eager-lewin-26fb51.netlify.app/.netlify/functions/test', JSON.stringify({ id, invoicecode: invoice, token: localStorage.getItem('token') }))
            .catch(err => setError(true))
            .then(res => {
                setSuccess(true);
            })
    }

    if (error) {
        return (
            <div className={styles.contractor}>
                <h1>SUBMIT INVOICE</h1>
                <Link className={styles.link} to='/'>SESSION TIMEOUT, CLICK HERE TO LOG IN AGAIN</Link>
            </div>);
    }

    const isDisabled = (arr: job_bcd[]) => {
        let disabled = false;

        arr.forEach(item => {
            Object.keys(item).forEach(field => {
                if (String(item[field]) == '' || item[field] == null) disabled = true;
            })
        })

        return disabled;
    }

    // if cell started but not finished, error 
    // return cells with only one field (not date)
    const showFieldsError = [...dayworks, ...mistcoats, ...decoration]
    .some(item => {
        const completedFields = Object.keys(item).filter(key=> {
            return key !== 'date_completed' && item[key] !== null && item[key] !== ''; 
        }).length;

        return (completedFields > 0 && completedFields < 4);
    })

    return (
        <div className={styles.contractor}>
            <h1>SUBMIT INVOICE</h1>
            <div>
                {content}
                <h3>DECORATION</h3>
                {decorationhtml}
                <button disabled={isDisabled(decoration)} onClick={() => addField(decoration, setDecoration)}>add more</button>

                <h3>MISTCOATS</h3>
                {mistcoatshtml}
                <button disabled={isDisabled(mistcoats)} onClick={() => addField(mistcoats, setMistcoats)}>add more</button>

                <h3>DAYWORKS</h3>
                {dayworkshtml}
                <button disabled={isDisabled(dayworks)} onClick={() => addField(dayworks, setDayworks)}>add more</button>

                <div className={styles.totals}>
                    <h3>TOTALS</h3>
                    <h4>SUBTOTAL: £{totals.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                    <p>{VAT ? 'VAT 20%: £' + VATTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'VAT 0%: £0.00'}</p>
                    <button onClick={() => setVAT(!VAT)}>{VAT ? 'REMOVE VAT' : 'APPLY VAT'}</button>
                    <br />
                    <p>{CIS ? 'CIS Tax | Deduct 20%: -£' + CISTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'CIS Tax | Deduct 0%'}</p>
                    <button onClick={() => setCIS(!CIS)}>{CIS ? 'CHANGE TO GROSS STATUS | DEDUCT 0%' : 'APPLY CIS TAX | DEDUCT 20%'}</button>

                    <h2>INVOICE TOTAL: £{finaltotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                    <p>
                        By pressing the SUBMIT INVOICE button below, I confirm that all of these works have been completed. A copy of this invoice will also be emailed to you
                    </p>
                    {
                        showFieldsError && 
                        <p className={styles.error}>Please do not leave partially completed work items</p>
                    }
                </div>
                {
                    !showFieldsError && 
                    <>
                        {showSubmit ? <button className={styles.submit} onClick={() => submit()}>SUBMIT INVOICE</button> : null}
                    </>
                }
            </div>
        </div>
    )
}


