import React, { useContext, useState } from 'react';

import { Link, Redirect } from 'react-router-dom';

import { LoginContext } from '../context/Login';

import styles from './loginModal.module.scss';

interface LoginProps {
    loginType: string,
}

interface creds {
    [key: string]: string
}

export default function LoginModal(props: LoginProps) {

    const loginContext = useContext(LoginContext);

    const initialProps = {
        email: localStorage.getItem('savedEmail') || '', password: localStorage.getItem('savedPassword') || ''
    }

    
    const [state, setState] = useState<creds>(initialProps);
    const [remember, setRemember] = useState<boolean>(false);

    if (loginContext.loginState.success) {
        if (loginContext.loginState.email.toLowerCase() == process.env.REACT_APP_ADMINEMAIL) return <Redirect to='/admin' />;
        if (loginContext.loginState.email.toLowerCase() != process.env.REACT_APP_ADMINEMAIL) return <Redirect to='/contractor' />;
    }

    const login = () => {
        if (remember) {
            localStorage.setItem('savedEmail', state.email);
            localStorage.setItem('savedPassword', state.password);
        } else {
            localStorage.removeItem('savedEmail');
            localStorage.removeItem('savedPassword');
        }
        loginContext.submit(state);
    }

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        let newState = { ...state };
        newState[field] = e.target.value;
        setState(newState);
    }

    let disabled = true;

    if ((state.email.toLowerCase() == process.env.REACT_APP_ADMINEMAIL && props.loginType == 'ADMIN') || props.loginType != 'ADMIN') {
        disabled = false;
    }

    let mystyles = [styles.loginModal];

    if (loginContext.loginState.error) {
        mystyles.push(styles.loginFailed)
    }

    return (
        <div className={mystyles.join(" ")}>
            <h1>
                LOGIN {props.loginType}
            </h1>
            <input name='email' placeholder='Email' type="text" value={state.email} onChange={(e) => inputHandler(e, 'email')} />
            <input name='password' placeholder='Password' type="text" value={state.password} onChange={(e) => inputHandler(e, 'password')} />
            <label>remember me             <input type='checkbox' onChange={() => setRemember(!remember)} />
            </label>
            <p>Please note: your session will expire in 1 hour- you will have to log back in</p>
            <button className={styles.button} onClick={() => login()} disabled={disabled}>
                LOGIN
                </button>
        </div>
    )
}